import { basicPlan, standardPlan, annualPlan } from '../../price-table/constants'

const isDowngrading = (subscription, upcomingSubscription) => {
  return (
    !!subscription &&
    !!upcomingSubscription &&
    (
      (
        subscription.sku === annualPlan.sku &&
        upcomingSubscription.sku === standardPlan.sku
      ) ||
      (
        subscription.sku === standardPlan.sku &&
        upcomingSubscription.sku === basicPlan.sku
      ) ||
      (
        subscription.sku === annualPlan.sku &&
        upcomingSubscription.sku === basicPlan.sku
      )
    )
  )
}

export default isDowngrading
